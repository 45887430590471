/** @jsx jsx */
import React from "react";
import Layout from "@/components/layout";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import { Helmet } from "react-helmet";
import { withPrefix} from "gatsby"
import Logo from "../../static/prova/Logo.svg"
import { jsx } from '@emotion/react'



import { 
    start_btn,
    info_box,
    result_box,
    quiz_box,
    Section,
    score_text,
    CommonBt,
    quitBt,
    nextBt,
    Footer,
  }  from '../assets/styles/Prova.style.js'

const QuestionsPage = () => {

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Vitalis Olimpíada de Medicina - Sobre Nós">

        <Helmet>
            <style>{`body { background-color: ${'#f1f1f1 !important'}; }`}</style>
            <script src={withPrefix('script.js')} type="text/javascript" /> 
            <script src={withPrefix('questions.js')} type="text/javascript" />
        </Helmet>

        <div css={start_btn} className="start_btn">
            <img src={Logo} alt="Celereitas" className="logo"/>
           <div className="buttons">
           <button css={CommonBt} className="op1">Prova categoria Júnior</button>
            <button css={CommonBt} className="op2">Prova categoria Sênior</button>
           </div>
        </div>

        <div css={info_box} className="info_box">
            <div className="info-title"><span>Regras para realização da prova</span></div>
            <div className="buttons">
                <button className="quit">Sair</button>
                <button className="restart">Continuar</button>
            </div>
        </div>

        <div css={quiz_box} className="quiz_box">
            <header>
                <div className="title"></div>
                <div className="time_line"></div>
                <button id="header-quit" css={[CommonBt,quitBt]} className="quit">Sair</button>
            </header>
            <section css={Section}>
                <div className="que_text">

                </div>
                <div className="option_list">

                </div>
            </section>
  
            <footer css={Footer}>
                <div className="total_que">

                </div>
                <button css={[CommonBt, nextBt]} className="next_btn">Próxima</button>
            </footer>
        </div>
    
        <div css={result_box} className="result_box">
            <div className="icon">
              
            </div>
           
            <div css={score_text} className="score_text">
               
            </div>
            <div className="result_question">
               
                <span></span>
            </div>
            <div className="buttons">
                <button css={[CommonBt, nextBt]} className="restart">Refazer</button>
                <button css={[CommonBt, quitBt]} className="quit">Sair</button>
            </div>
        </div>
        
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default QuestionsPage;
