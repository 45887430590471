/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "../styles/layout.styles";

export const start_btn = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 2px 1px #d3d2d3;
  border-radius: 2px;

  box-shadow: none;
  border-radius: 0;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .logo {
    width: 100%;
    max-width: 400px;
    margin-bottom: 40px;
  }
`;

// export const start_btn = css`
// `

export const info_box = css`
  width: 100%;
  max-width: 540px;
  background: #fff;
  border-radius: 5px;
  transform: translate(-50%, -50%) scale(0.9);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 2px 1px #d3d2d3;
  border-radius: 2px;

  & .activeInfo {
    opacity: 1;
    z-index: 5;
    pointer-events: auto;
    transform: translate(-50%, -50%) scale(1);
  }

  & .info-title {
    height: 60px;
    width: 100%;
    border-bottom: 1px solid lightgrey;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-radius: 5px 5px 0 0;
    font-size: 20px;
    font-weight: 600;
  }

  & .info-list {
    padding: 15px 30px;
    & .info {
      margin: 5px 0;
      font-size: 17px;
      span {
        font-weight: 600;
        color: #007bff;
      }
    }
  }

  & .buttons {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    border-top: 1px solid lightgrey;

    button {
      margin: 0 5px;
      height: 40px;
      width: 100px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 5px;
      border: 1px solid #007bff;
      transition: all 0.3s ease;
    }
  }
`;

export const result_box = css`
  background: #fff;
  /* border-radius: 5px; */
  display: flex;
  padding: 25px 30px;
  width: 100%;
  max-width: 990px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* transform: translate(-50%, -50%) scale(0.9); */
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 2px 1px #d3d2d3;
  border-radius: 2px;

  &.activeResult {
    opacity: 1;
    z-index: 5;
    pointer-events: auto;
    transform: translate(-50%, 0) scale(1);
    margin: 80px 0;

    @media only screen and (max-width: 600px) {
        transform: translate(-50%, -20%) scale(1);
    }
  }

  .icon img {
    margin-bottom: 10px;
    max-width: 250px;
  }

  .complete_text {
    font-size: 20px;
    font-weight: 500;
  }

  .score_text {
    width: 100%;

    span {
      display: flex;
      margin: 10px 0;
      font-size: 18px;
      font-weight: 500;

      p {
        padding: 0 4px;
        font-weight: 600;
      }
    }
  }

  .buttons {
    display: flex;
    margin: 20px 0;

    button {
        margin: 0 10px;
      /* margin: 0 10px;
      height: 45px;
      padding: 0 20px;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 5px;
      border: 1px solid #007bff;
      transition: all 0.3s ease; */

      /* &.restart {
        font-size: 14px;
        line-height: 10px;
        color: #fff;
        display: inline-block;
        padding: 19px 34px;
        background: #0e223a;
        font-weight: 800;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        border: none;
        border-radius: 45px;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background: #2b4b6f;
        }
      } */

      /* &.quit {
        font-size: 14px;
        line-height: 10px;
        display: inline-block;
        padding: 19px 34px;
        color: #0e223a;
        background: #52ccc3;
        font-weight: 800;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        border: none;
        border-radius: 45px;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background: #5ce3d9;
        }
      } */
    }
  }


`;

export const quiz_box = css`
  position: relative;
  width: 100%;
  max-width: 990px;
  background: #fff;
  border-radius: 5px;
  /* opacity: 0; */ 
  display: none;
  pointer-events: none;
  transition: all 0.3s ease;
  box-shadow: 0px 1px 2px 1px #d3d2d3;
  border-radius: 2px;
  transform: scale(0.9);
  margin: 80px auto;

  &.activeQuiz {
    /* opacity: 1; */
    display: block;
    z-index: 5;
    pointer-events: auto;
    transform: scale(1);
  }

  header {
    position: relative;
    z-index: 2;
    height: 70px;
    padding: 0 30px;
    background: #fff;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;

    .title {
      font-size: 20px;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 600px) {
        margin: 0px auto;
    }
`;

export const Section = css`
  padding: 25px 30px 20px 30px;
  background: #fff;

  .que_text {
    font-size: 16px;
    margin-bottom: 30px;

    p {
      margin-bottom: 12px;
    }

    h2 {
      margin-bottom: 20px;
    }

    ul {
      list-style-type: none;
      margin-bottom: 10px;

      li {
        padding: 2px 10px;
        color: #666;
      }
    }

    img {
      margin-bottom: 12px;
      max-width: 100%;
    }

    span {
      background-color: #355bb7;
      margin-bottom: 10px;
      display: inline-block;
      color: #fff;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 14px;
    }
  }

  .option_list {
    padding: 20px 0px;
    display: block;

    .option {
      background: #f9f9f9;
      border: 1px solid #cecece;
      border-radius: 5px;
      padding: 8px 40px 8px 46px;
      font-size: 17px;
      margin-bottom: 15px;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &:hover {
        color: #004085;
        background: #cce5ff;
        border: 1px solid #b8daff;
      }

      &:hover::before,
      &.selected::before {
        background-color: #7abbff;
      }

      &::before {
        content: "";
        width: 36px;
        height: 100%;
        background-color: #b3b3b3;
        position: absolute;
        left: 0;
      }

      & .correct {
        color: #155724;
        background: #d4edda;
        border: 1px solid #c3e6cb;
      }

      &.selected {
        color: #004085;
        background: #cce5ff;
        border: 1px solid #b8daff;
      }
      &.incorrect {
        color: #721c24;
        background: #f8d7da;
        border: 1px solid #f5c6cb;
      }
      &.disabled {
        pointer-events: none;
      }

      .option_index {
        color: white;
        position: absolute;
        left: 10px;
        font-size: 1.3em;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      .icon {
        height: 26px;
        width: 26px;
        border: 2px solid transparent;
        border-radius: 50%;
        text-align: center;
        font-size: 13px;
        pointer-events: none;
        transition: all 0.3s ease;
        line-height: 23px;
        position: absolute;
        right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .icon.tick {
        color: #004085;
        border-color: #004085;
        background: #cce5ff;
      }

      .icon.cross {
        color: #004085;
        background: #f8d7da;
        border-color: #a42834;
      }

      .dot {
        display: block;
        background: #004085;
        width: 14px;
        height: 14px;
        border-radius: 50%;
      }
    }
  }
`;

export const Footer = css`
  height: 70px;
  padding: 0 30px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid lightgrey;

  .total_que span {
    display: flex;
    user-select: none;

    p {
      font-weight: 500;
      padding: 0 5px;

      &:first-of-type {
        padding-left: 0px;
      }
    }
  }

  button {
    /* font-size: 14px;
    line-height: 10px;
    color: #fff;
    display: inline-block;
    padding: 19px 34px;
    background: #0e223a;
    font-weight: 800;
    border: none;
    border-radius: 45px;
    text-transform: uppercase;
    cursor: pointer; */

    opacity: 0;
    pointer-events: none;
    transform: scale(0.95);
    transition: all 0.3s ease;

    /* &:hover {
      background: #0263ca;
    } */

    &.show {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1);
    }
  }
`;

export const score_text = css`
  .final_que {
    width: 100%;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;

    & .c-wrong {
      color: #b40000;
    }

    & .c-correct {
      color: #23903c;
    }

    &.wrong {
      background-color: #ffefef;
    }
    &.correct {
      background-color: #f5fff6;
    }

    i {
      font-size: 1.2em;
    }
  }
`;

export const CommonBt = css`
  top: 0;
  right: 0;
  padding: 2px 16px;
  box-shadow: 5px 5px 0px 0px #383838;
  border: 1px solid #383838;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  background: ${colors.secondaryVar1};
  color: ${colors.white};
  text-align: center;
  display: inline-block;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all 0.19s linear 0s;
  -moz-transition: all 0.19s linear 0s;
  -webkit-transition: all 0.19s linear 0s;
  -ms-transition: all 0.19s linear 0s;
  -o-transition: all 0.19s linear 0s;
  font-size: 18px;
  padding: 10px 60px;
  cursor: pointer;
  margin: 10px;

  &:hover {
    background: ${colors.secondaryVar3};
    color: #000;
    text-decoration: none;
    top: 5px;
    right: -5px;
    box-shadow: 2px 2px 0px #383838;
    &:after {
      width: 100%;
    }
  }
`;

export const quitBt = css`
  background: #b1b1b1;
  padding: 4px 40px;
  font-size: 16px;
  margin: 0;

  &:hover {
    background: #bbbbbb;
  }
`;

export const nextBt = css`
  padding: 4px 40px;
  font-size: 16px;
  margin: 0;
`;